.Player-container {
  display: flex;
  flex-direction: row;
}

.Player-position {
  font-weight: bold;
  font-size: calc(10px + 2vmin);
  align-content: center;
  margin-right: 2vw;
  width: 3vw;
  text-align: right;

  text-shadow: -1px -1px 0 #fff, /* Top left shadow */ 1px -1px 0 #fff, /* Top right shadow */ -1px 1px 0 #fff, /* Bottom left shadow */ 1px 1px 0 #fff; /* Bottom right shadow */
}

.Player-position1 {
  color: gold;
  font-weight: bold;
  font-size: calc(10px + 2vmin);
  align-content: center;
  margin-right: 2vw;
  width: 3vw;
  text-align: right;

  text-shadow: -1px -1px 0 #000, /* Top left shadow */ 1px -1px 0 #000, /* Top right shadow */ -1px 1px 0 #000, /* Bottom left shadow */ 1px 1px 0 #000; /* Bottom right shadow */
}

.Player-position2 {
  color: silver;
  font-weight: bold;
  font-size: calc(10px + 2vmin);
  align-content: center;
  margin-right: 2vw;
  width: 3vw;
  text-align: right;

  text-shadow: -1px -1px 0 #000, /* Top left shadow */ 1px -1px 0 #000, /* Top right shadow */ -1px 1px 0 #000, /* Bottom left shadow */ 1px 1px 0 #000; /* Bottom right shadow */
}

.Player-position3 {
  color: #cd7f32;
  font-weight: bold;
  font-size: calc(10px + 2vmin);
  align-content: center;
  margin-right: 2vw;
  width: 3vw;
  text-align: right;

  text-shadow: -1px -1px 0 #000, /* Top left shadow */ 1px -1px 0 #000, /* Top right shadow */ -1px 1px 0 #000, /* Bottom left shadow */ 1px 1px 0 #000; /* Bottom right shadow */
}

.LastYear-title {
  position: absolute;
  font-size: calc(10px + 2vmin);
  font-weight: bold;
  text-shadow: -1px -1px 0 #ddd, /* Top left shadow */ 1px -1px 0 #ddd, /* Top right shadow */ -1px 1px 0 #ddd, /* Bottom left shadow */ 1px 1px 0 #ddd; /* Bottom right shadow */
  /* border: 2px solid aqua; */
  width: 31vh;
  left: -8vh;
  top: -26vh;
  text-align: center;
  background-color: #777;
  border-radius: 2vh;
}

.Player1 {
  position: absolute;
  bottom: 0;
  left: -5vh;  
}

.Player2 {
  position: absolute;
  bottom: 0;
  left: -20vh;
  transform: rotate(-20deg);
}

.Player3 {
  position: absolute;
  bottom: 0;
  left: 20vh;
  transform: rotate(20deg);
}

.Player-avatar1 {
  width: calc(25vh);
  height: calc(25vh);
  object-fit: cover;
  border-style: solid;
  border-color: gold;
  border-radius: 1vh;
}

.Player-avatar2 {
  width: calc(15vh);
  height: calc(15vh);
  object-fit: cover;
  border-style: solid;
  border-color: silver;
  border-radius: 1vh;
}

.Player-avatar3 {
  width: calc(10vh);
  height: calc(10vh);
  object-fit: cover;
  border-style: solid;
  border-color: #cd7f32;
  border-radius: 1vh;
}

.Player-name1 {
  font-size: 3vh;
  font-weight: bold;
  color: gold;
  width: 25vh;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow: -1px -1px 0 #000, /* Top left shadow */ 1px -1px 0 #000, /* Top right shadow */ -1px 1px 0 #000, /* Bottom left shadow */ 1px 1px 0 #000; /* Bottom right shadow */
}

.Player-name2 {
  font-size: 2vh;
  font-weight: bold;
  color: silver;
  width: 15vh;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow: -1px -1px 0 #000, /* Top left shadow */ 1px -1px 0 #000, /* Top right shadow */ -1px 1px 0 #000, /* Bottom left shadow */ 1px 1px 0 #000; /* Bottom right shadow */
}

.Player-name3 {
  font-size: 1vh;
  font-weight: bold;
  color: #cd7f32;
  width: 10vh;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow: -1px -1px 0 #000, /* Top left shadow */ 1px -1px 0 #000, /* Top right shadow */ -1px 1px 0 #000, /* Bottom left shadow */ 1px 1px 0 #000; /* Bottom right shadow */
}

.Player-points {
  font-size: calc(1.4vw);
  font-style: italic;
  margin-left: 1vw;
  text-shadow: -1px -1px 0 #ddd, /* Top left shadow */ 1px -1px 0 #ddd, /* Top right shadow */ -1px 1px 0 #ddd, /* Bottom left shadow */ 1px 1px 0 #ddd; /* Bottom right shadow */
}

.Player-GoingUp {
  color: lime;
  font-size: calc(10px + 2vmin);
  border: 2px solid black;
  border-radius: 8px; /* Adjust as needed */
  background-color: #000;
  margin-right: 0.5vw;
}

.Player-GoingDown {
  color: red;
  font-size: calc(10px + 2vmin);
  border: 2px solid black;
  border-radius: 8px; /* Adjust as needed */
  background-color: #000;
  margin-right: 0.5vw;
}
