* {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

body,
html {
  padding: 3px 3px 3px 3px;

  background-color: #d8dbe2;

  font-family: Verdana, sans-serif;
  text-align: center;
}

div.main_page {
  position: relative;
  /* display: table; */

  width: 100%;

  margin-bottom: 3px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 0px 0px 0px;

  border-width: 2px;
  border-color: #212738;
  border-style: solid;
  border-radius: 30px;

  background-color: #ffffff;

  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.woohooologo {
  margin-top: 20px;
}

.rankytanklogo {
  border-radius: 45px;
  border-color: #000000;
  border-width: 2px;
  border-style: solid;
  margin-top: 20px;
  margin-bottom: 20px;
}

.rankytanktitle {
  margin-top: 10px;
}

.rankytanksubtitle {
  font-family: arial;
}

.footer {
  font-weight: bold;
}

@media screen and (min-width: 500px) {
  .footer {
    font-size: 20px;
  }

  .rankytanksubtitle {
    font-size: 20px;
  }
}

@media screen and (max-width: 500px) and (min-width: 0px) {
  .footer {
    font-size: 4vw;
  }

  .rankytanksubtitle {
    font-size: 4vw;
  }
}

.woohooologo {
  width: 80%;
  max-width: 767px;
}

.rankytanktitle {
  width: 50%;
  max-width: 360px;
}

.rankytanklogo {
  width: 50%;
  max-width: 307px;
}

.rankytankapplestore {
}

.rankytankgoogleplay {
  margin-top: 5px;
  margin-bottom: 5px;
}

.page {
  display: flex;
  flex-direction: column;
  height: 1000px;
}
