.App {
  text-align: center;
}
/* 
.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Period-title {
  font-size: calc(4vw);
  font-weight: bold;
  margin-bottom: -1vw;
  margin-top: 1vw;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-shadow: -1px -1px 0 #ddd, /* Top left shadow */ 1px -1px 0 #ddd, /* Top right shadow */ -1px 1px 0 #ddd, /* Bottom left shadow */ 1px 1px 0 #ddd; /* Bottom right shadow */
}

.TopPlayersForPeriod-container {
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  /* border: 5px solid red; */
  padding-top: 2.5vw; /* ensure this is in sync with const paddingTop = ?? */
  margin-top: 0vw;
  /* -webkit-mask-image: linear-gradient(to bottom, black 100px, white 100px);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%; */

  /* -webkit-mask-image: linear-gradient(0deg, #000 99%, transparent); */
  -webkit-mask-image: linear-gradient(180deg, transparent 0px, black 5vh);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;

  /* Equivalent for standardization (non-webkit browsers) */
  /* mask-image: linear-gradient(black 100px, transparent 100px);
  mask-repeat: no-repeat;
  mask-size: 100% 100px; */
}

.TopPlayersForPeriod-container-spacing {
  width: 100%;
  height: 3vh;
}

.TopPlayersForPeriod-container-anim {
  /* transform: translateY(-100px); */
}

.TopPlayersForPeriod-empty-container {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-style: italic;
  font-size: calc(10px + 2vmin);
}

.App-container {
  align-content: center;
  /* border-style: dotted; */
  /* align-items: center;
    justify-content: center; */
  display: flex;
  flex-direction: column;
  background: url('./assets/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-height: 100vh;
  max-width: 100vw;
  min-height: 100vh;
  min-width: 100vw;
}

.App-containerXMASSharedPre {
  align-content: center;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  max-width: 100vw;
  min-height: 100vh;
  min-width: 100vw;
}

.App-containerXMAS {
  background: url('./assets/xmas.png') center no-repeat;
  background-size: 100% 100%;
}

.App-containerXMASParty10 {
  background: url('./assets/christmaspartyloading10.jpg') center no-repeat;
  background-size: 100% 100%;
}

.App-containerXMASParty25 {
  background: url('./assets/christmaspartyloading25.jpg') center no-repeat;
  background-size: 100% 100%;
}

.App-containerXMASParty42 {
  background: url('./assets/christmaspartyloading42.jpg') center no-repeat;
  background-size: 100% 100%;
}

.App-containerXMASParty55 {
  background: url('./assets/christmaspartyloading55.jpg') center no-repeat;
  background-size: 100% 100%;
}

.App-containerXMASParty67 {
  background: url('./assets/christmaspartyloading67.jpg') center no-repeat;
  background-size: 100% 100%;
}

.App-containerXMASParty83 {
  background: url('./assets/christmaspartyloading83.jpg') center no-repeat;
  background-size: 100% 100%;
}

.App-containerXMASParty99dot9 {
  background: url('./assets/christmaspartyloading99dot9.jpg') center no-repeat;
  background-size: 100% 100%;
}

.App-containerXMASPartyItsTime {
  background: url('./assets/christmasparty2.png') center no-repeat;
  background-size: 100% 100%;
}

.App-containerXMASPartyDone {
  background: url('./assets/christmaspartyafter.jpg') center no-repeat;
  background-size: 100% 100%;
}

.App-containerXMASKidsParty {
  background: url('./assets/kidschristmasparty.jpg') center no-repeat;
  background-size: 100% 100%;
}

.App-containerXMAS-topleft {
  position: absolute;
  background: url('./assets/xmas-topleft.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 50vw;
  aspect-ratio: 1442 / 825;
  /* opacity: 0.9; */
}

.App-containerXMAS-bottomright {
  position: absolute;
  background: url('./assets/xmas-bottomright.png');
  /* width: 1141px;
    height: 1187px;     */

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 50vw;
  aspect-ratio: 1141 / 1187;

  /* opacity: 0.9; */
  bottom: 0;
  right: 0;
}

.App-logo-container {
  /* border-style: dotted; */
  position: absolute;
  transform: rotate(-4deg);
  top: 1vh;
  z-index: 1;
}

.App-logo {
  width: 12vw;
  margin-left: 0.5vw;
  margin-top: 0.5vw;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 3s linear;
  }
}

@keyframes App-logo-spin {
  0% {
    opacity: 1;
    transform: rotate(-2deg);
  }
  50% {
    opacity: 0.6;
    transform: rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: rotate(-2deg);
  }
}

.App-period-container {
  display: flex;
  flex-direction: row;
  align-content: space-evenly;
  justify-content: center;
  z-index: 1;
}

.Period-year-container {
  margin-right: 1vw;
  /* float: left; */
  /* display: flex; */
  flex: 1;
  /* border-style: dotted; */
}

.Period-week-container {
  margin-left: 1vw;
  flex: 1;
  /* float: right; */
  /* display: flex; */
  /* border-style: dotted; */
}

.App-is-down {
  height:100vh;  
  width:100vw;
  align-content: center;  
  text-align: center;
  font-size: calc(10px + 2vmin);
  text-shadow: -1px -1px 0 #ddd, /* Top left shadow */ 1px -1px 0 #ddd, /* Top right shadow */ -1px 1px 0 #ddd, /* Bottom left shadow */ 1px 1px 0 #ddd; /* Bottom right shadow */
}