.container {
    /* position: absolute;
    bottom:0;
    top:0;
    left:0;
    right:0;
    align-items: center;
    justify-content: center; */
    display: flex;
    height:100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: black;
}

.ugedag {
    display: flex;
    justify-content: center;
    align-items: center;    
    /* border: 1px solid red; */
}

.ugedag-language {
    display: flex;
    justify-content: center;
    align-items: center;    
    /* border: 1px solid red; */
    color: darkblue;
    font-size: 5vh;    
}

.ugedag-joke {
    margin-top: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;    
    /* border: 1px solid red; */
    font-size: 5vh;
    margin-left: 5vw;
    margin-right: 5vw;
    color: white;
}