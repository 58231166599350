.Player-container {
  display: flex;
  flex-direction: row;
}

.Player-position {
  font-weight: bold;
  font-size: calc(10px + 2vmin);
  align-content: center;
  margin-right: 2vw;
  width: 3vw;
  text-align: right;

  text-shadow: -1px -1px 0 #fff, /* Top left shadow */ 1px -1px 0 #fff, /* Top right shadow */ -1px 1px 0 #fff, /* Bottom left shadow */ 1px 1px 0 #fff; /* Bottom right shadow */
}

.Player-position1 {
  color: gold;
  font-weight: bold;
  font-size: calc(10px + 2vmin);
  align-content: center;
  margin-right: 2vw;
  width: 3vw;
  text-align: right;

  text-shadow: -1px -1px 0 #000, /* Top left shadow */ 1px -1px 0 #000, /* Top right shadow */ -1px 1px 0 #000, /* Bottom left shadow */ 1px 1px 0 #000; /* Bottom right shadow */
}

.Player-position2 {
  color: silver;
  font-weight: bold;
  font-size: calc(10px + 2vmin);
  align-content: center;
  margin-right: 2vw;
  width: 3vw;
  text-align: right;

  text-shadow: -1px -1px 0 #000, /* Top left shadow */ 1px -1px 0 #000, /* Top right shadow */ -1px 1px 0 #000, /* Bottom left shadow */ 1px 1px 0 #000; /* Bottom right shadow */
}

.Player-position3 {
  color: #cd7f32;
  font-weight: bold;
  font-size: calc(10px + 2vmin);
  align-content: center;
  margin-right: 2vw;
  width: 3vw;
  text-align: right;

  text-shadow: -1px -1px 0 #000, /* Top left shadow */ 1px -1px 0 #000, /* Top right shadow */ -1px 1px 0 #000, /* Bottom left shadow */ 1px 1px 0 #000; /* Bottom right shadow */
}

.Player-name {
  /* background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  color: white; */
  font-size: calc(10px + 2vmin);
  font-weight: bold;
  width: 30vw;
  margin-left: 1vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow: -1px -1px 0 #ddd, /* Top left shadow */ 1px -1px 0 #ddd, /* Top right shadow */ -1px 1px 0 #ddd, /* Bottom left shadow */ 1px 1px 0 #ddd; /* Bottom right shadow */
}

.Player-avatar {
  /* width: calc(8vh); */
  /* height: auto; */
  width: calc(8vh);
  height: calc(8vh);
  /* object-fit: contain; */
  object-fit: cover;
  border-style: solid;
  border-color: gray;
}

.Player-points {
  font-size: calc(1.4vw);
  font-style: italic;
  margin-left: 1vw;
  text-shadow: -1px -1px 0 #ddd, /* Top left shadow */ 1px -1px 0 #ddd, /* Top right shadow */ -1px 1px 0 #ddd, /* Bottom left shadow */ 1px 1px 0 #ddd; /* Bottom right shadow */
}

.Player-GoingUp {
  color: lime;
  font-size: calc(10px + 2vmin);
  border: 2px solid black;
  border-radius: 8px; /* Adjust as needed */
  background-color: #000;
  margin-right: 0.5vw;
}

.Player-GoingDown {
  color: red;
  font-size: calc(10px + 2vmin);
  border: 2px solid black;
  border-radius: 8px; /* Adjust as needed */
  background-color: #000;
  margin-right: 0.5vw;
}
